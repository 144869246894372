import React from "react"


class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            avatar_url:"https://avatars.githubusercontent.com/u/69302858?s=400&u=203545d77b9d279674f44f8317441d4cd8110083&v=4",
            login: "woosal1337",
            name: "Vusal Ismayilov",
            followers: 100,
            following: 100,
            public_repos: 100
        }
        this.buttonHandle = this.buttonHandle.bind(this)
        this.userSearch = this.userSearch.bind(this)
    }

    buttonHandle(event) {
        event.preventDefault()
        fetch(`https://api.github.com/users/${this.state.login}`)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    avatar_url:response.avatar_url,
                    name:response.name,
                    followers:response.followers,
                    following:response.following,
                    public_repos:response.public_repos
                })
            })
    }

    userSearch(event) {
        const {value} = event.target
        this.setState({
            login:value
        })

        console.log(value)
    }

    render() {
        return (
            <div className="s130">
                <form>
                    <div className="inner-form">
                        <div className="input-field first-wrap">
                            <div className="svg-wrapper">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                </svg>
                            </div>
                            <input id="search" type="text" placeholder="GitHub Profile Name?" onChange={this.userSearch}/>
                        </div>
                        <div className="input-field second-wrap">
                            <button className="btn-search" type="submit" onClick={this.buttonHandle}>Search</button>
                        </div>
                    </div>

                    <div className="center">
                        <h1>@{this.state.login}</h1>
                    </div>

                    <div className="cards-list">

                        <div className="card 1">
                            <div className="card_image"><img src={this.state.avatar_url} alt="first-card"/></div>
                            <div className="card_title title-white">
                                <p>Name: {this.state.name}</p>
                            </div>
                        </div>

                        <div className="card 2">
                            <div className="card_image">
                                <img src={this.state.avatar_url} alt="second-card"/>
                            </div>
                            <div className="card_title title-white">
                                <p>Following: {this.state.following}</p>
                            </div>
                        </div>

                        <div className="card 3">
                            <div className="card_image">
                                <img src={this.state.avatar_url} alt="third-card"/>
                            </div>
                            <div className="card_title title-white">
                                <p>Followers: {this.state.followers}</p>
                            </div>
                        </div>

                        <div className="card 4">
                            <div className="card_image">
                                <img src={this.state.avatar_url} alt="fourth-card"/>
                            </div>
                            <div className="card_title title-white">
                                <p>Repos: {this.state.public_repos}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default App;
